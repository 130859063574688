import React, { createContext, useState, useReducer } from 'react'
import { svgAttrsReducer } from '../reducers/reducer';

export const SVGContext = createContext()
export const SVGCodeContext = createContext()
export const GridContext = createContext()

export const SVGProvider = ({ children }) => {

  const [SVGElements, setSVGElements] = useReducer(svgAttrsReducer, []);
  const [SVGCode, setSVGCode] = useState('');
  const [showGrid, setShowGrid] = useState(false);

  // // Persist state in localStorage and react state
  // const useStickyState = (defaultValue, key) => {
  //   const [SVGElements, setSVGElements] = useState(() => {
  //     const stickyValue = localStorage.getItem(key);
  //     return stickyValue !== null
  //       ? JSON.parse(stickyValue)
  //       : defaultValue;
  //   });
  //   useEffect(() => {
  //     localStorage.setItem(key, JSON.stringify(SVGElements));
  //   }, [key, SVGElements]);
  //   return [SVGElements, setSVGElements];
  // }

  return (
    <SVGContext.Provider value={[SVGElements, setSVGElements]}>
      <SVGCodeContext.Provider value={[SVGCode, setSVGCode]}>
        <GridContext.Provider value={[showGrid, setShowGrid]}>
          {children}
        </GridContext.Provider>
      </SVGCodeContext.Provider>
    </ SVGContext.Provider >
  )
}