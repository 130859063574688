import React, { Fragment, createElement, useContext } from 'react';
import { SVGContext } from "./context/context";
import uniqid from "uniqid";

// import { Draggable } from 'react-beautiful-dnd';

const Layer = () => {

  const [SVGElements, setSVGElements] = useContext(SVGContext);
  // const [inputWidth, setInputWidth] = useState();

  // const inputStyle = {
  //   minWidth: '20px',
  //   width: inputWidth + 'px'
  // }

  return (

    <Fragment>
      {
        Object.entries(SVGElements)
          .reverse()
          .map((element, key) => (

            // <Draggable key={key} draggableId={element[1].nodeId}>

            <li className="layerList-item" key={key}>

              <div className="layerList-item-element">
                {`<${element[1].nodeType}`}

                <nav className="layerList-item-actions">
                  <button
                    title="Duplicate Layer"
                    onClick={() => setSVGElements({ type: 'CLONE_NODE', prevState: element[1], nodeId: uniqid() })}
                  >
                    <svg viewBox="0 0 15 15" fill="none" width="15" height="15"><path d="M10.5.5l.277-.416L10.651 0H10.5v.5zm3 2h.5v-.268l-.223-.148-.277.416zm-1 9.5h-8v1h8v-1zM4 11.5v-10H3v10h1zM4.5 1h6V0h-6v1zM13 2.5v9h1v-9h-1zM10.223.916l3 2 .554-.832-3-2-.554.832zM4.5 12a.5.5 0 01-.5-.5H3A1.5 1.5 0 004.5 13v-1zm8 1a1.5 1.5 0 001.5-1.5h-1a.5.5 0 01-.5.5v1zM4 1.5a.5.5 0 01.5-.5V0A1.5 1.5 0 003 1.5h1zm-3 2v10h1v-10H1zM2.5 15h8v-1h-8v1zm0-12h1V2h-1v1zM12 13.5v-1h-1v1h1zM10.5 15a1.5 1.5 0 001.5-1.5h-1a.5.5 0 01-.5.5v1zM1 13.5A1.5 1.5 0 002.5 15v-1a.5.5 0 01-.5-.5H1zm1-10a.5.5 0 01.5-.5V2A1.5 1.5 0 001 3.5h1z" fill="currentColor"></path></svg>
                  </button>

                  <button
                    className="deleteButton"
                    title="Delete Layer"
                    onClick={(e) =>
                      setSVGElements({ nodeId: element[1].nodeId, type: 'DELETE_NODE' })
                    }
                  >
                    <svg className="deleteButton-icon" viewBox="0 0 15 15" fill="none" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg>
                  </button>

                  <button role="presentation" className="moveButton">
                    <svg className="moveButton-icon" viewBox="0 0 15 15" fill="none" width="15" height="15"><path d="M9.5 3a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1zm-4-10a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1z" stroke="currentColor"></path></svg>
                  </button>

                </nav>


                {/* <svg
                  width="30"
                  viewBox={"-50 -50 100 100"}
                  xmlns="http://www.w3.org/2000/svg"
                >

                  {Object.entries(element)
                    .map((isolatedNode) => {
                      console.log(isolatedNode[1])
                      let node = createElement(isolatedNode[1], { ...isolatedNode })
                      return node
                    })}

                </svg> */}

              </div>

              <div className="layerList-item-node-wrapper">

                {Object.entries(element[1])
                  .filter(([key]) => key !== 'nodeId')
                  .filter(([key]) => key !== 'nodeType')
                  .map(([key, value]) => (

                    <div className={`layerList-item-node${[value == null ? ' is-inactive' : '']}`} key={key}>

                      <label className="layerList-item-node-label"> {[key]}
                        <span role="presentation">{`="`}</span>
                        <input
                          placeholder={[value]}
                          // onKeyUp={(e) => setInputWidth(e.target.value.length * 10)}
                          // style={inputStyle}
                          onChange={(e) =>
                            setSVGElements({ nodeId: element[1].nodeId, type: 'UPDATE_ELEMENT_ATTRS', attrKey: [key], attrValue: e.target.value })
                          }
                        />
                        <span role="presentation" > {`"`}</span>
                      </label>

                    </div>
                  ))}

              </div>

              <div className="layerList-item-element">
                {`/> `}
              </div>


              {/* <div className="popUp">
                <h2>Are you sure you want to delete this layer?</h2>
              </div> */}

            </li>

            // </Draggable>
          ))
      }
    </Fragment>
  )
}

export default Layer