import React, { useState } from 'react';
import { SVGProvider } from './context/context'

import ActionsNav from './ActionsNav';
import SVGBox from './SVGBox';
import SVGLayers from './SVGLayers';

function Create() {

  // const [showSVGcode, setSVGcode] = useState();

  // const handleClassChange = e => {
  //   if (e.currentTarget.value.includes(" ")) {
  //     e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
  //   }
  //   setSVGId(e.target.value);
  // }

  return (
    <SVGProvider>
      <ActionsNav />
      <main id="create">
        <SVGLayers />
        <SVGBox />
      </main>
    </SVGProvider>
  );
}

export default Create;
