import React, { useContext } from 'react';
import { SVGContext, SVGCodeContext, GridContext } from "./context/context";
import uniqid from "uniqid";

const ActionsNav = () => {

  const [SVGElements, setSVGElements] = useContext(SVGContext);
  const [showGrid, setShowGrid] = useContext(GridContext);

  const addCircle = (e) => {
    e.preventDefault();
    setSVGElements({ type: 'ADD_CIRCLE', nodeId: uniqid(), nodeType: 'circle' })
  }

  const addRectangle = (e) => {
    e.preventDefault();
    setSVGElements({
      type: 'ADD_RECT', nodeId: uniqid(), nodeType: 'rect'
    })
  }

  const addEllipse = (e) => {
    e.preventDefault();
    setSVGElements({ type: 'ADD_ELLIPSE', nodeId: uniqid(), nodeType: 'ellipse' })
  }

  const addLine = (e) => {
    e.preventDefault();
    setSVGElements({ type: 'ADD_LINE', nodeId: uniqid(), nodeType: 'line' })
  }

  const addPolygon = (e) => {
    e.preventDefault();
    setSVGElements({ type: 'ADD_POLYGON', nodeId: uniqid(), nodeType: 'polygon' })
  }

  const addPolyline = (e) => {
    e.preventDefault();
    setSVGElements({ type: 'ADD_POLYLINE', nodeId: uniqid(), nodeType: 'polyline' })
  }

  return (
    <div className="actionsNav-wrapper">
      <nav className="shapesNav">

        {/* Circle */}
        <div className="toolTip-wrapper">
          <button onClick={addCircle} aria-labelledby="circle-label" title="Add Circle">
            <svg viewBox="0 0 15 15" fill="none" width="15" height="15"><path d="M.5 7.5a7 7 0 1014 0 7 7 0 00-14 0z" stroke="currentColor"></path></svg>
            <span className="visually-hidden">Add Circle</span>
          </button>

          <div role="tooltip" id="circle-label" className="align-right">Add Circle</div>
        </div>

        {/* Rect */}
        <div className="toolTip-wrapper">
          <button onClick={addRectangle} aria-labelledby="rect-label" title="Add Rectangle">
            <svg viewBox="0 0 15 15" fill="none" width="15" height="15"><path d="M13.5.5h-12a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1v-12a1 1 0 00-1-1z" stroke="currentColor"></path></svg>
            <span className="visually-hidden">Add Rectangle</span>
          </button>

          <div role="tooltip" id="rect-label">Add Rectangle</div>
        </div>

        {/* Ellipse */}
        <div className="toolTip-wrapper">
          <button onClick={addEllipse} aria-labelledby="ellipse-label" title="Add Ellipse">
            <svg style={{ transform: 'scaleX(1.25)' }} viewBox="0 0 15 15" fill="none" width="15" height="7.5"><path d="M.5 7.5a7 7 0 1014 0 7 7 0 00-14 0z" stroke="currentColor"></path></svg>

            <span className="visually-hidden">Add Ellipse</span>
          </button>

          <div role="tooltip" id="ellipse-label">Add Ellipse</div>
        </div>

        {/* Line */}
        <div className="toolTip-wrapper">
          <button onClick={addLine} aria-labelledby="line-label" title="Add Line">
            <svg viewBox="0 0 15 15" fill="none" width="15" height="15"><path d="M2 2l11 11M1.5 2.5a1 1 0 110-2 1 1 0 010 2zm12 12a1 1 0 110-2 1 1 0 010 2z" stroke="currentColor"></path></svg>
            <span className="visually-hidden">Add Line</span>
          </button>

          <div role="tooltip" id="line-label">Add Line</div>
        </div>

        {/* Polygon */}
        <div className="toolTip-wrapper">
          <button onClick={addPolygon} aria-labelledby="polygon-label" title="Add Polygon">
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 1.5l-7 12h14l-7-12z" stroke="currentColor" strokeLinejoin="round"></path></svg>
            <span className="visually-hidden">Add Polygon</span>
          </button>

          <div role="tooltip" id="polygon-label">Add Polygon</div>
        </div>

        {/* Polyline */}
        <div className="toolTip-wrapper">
          <button onClick={addPolyline} aria-labelledby="polyline-label" title="Add Polyline">
            <svg viewBox="0 0 15 15" fill="none" width="15" height="15"><path d="M2.5 1.5a1 1 0 11-2 0 1 1 0 012 0zm0 0h5v12h5m0 0a1 1 0 102 0 1 1 0 00-2 0z" stroke="currentColor"></path></svg>
            <span className="visually-hidden">Add Polyline</span>
          </button>

          <div role="tooltip" id="polyline-label">Add Polyline</div>
        </div>

        {/* Path */}
        <div className="toolTip-wrapper">
          <button aria-labelledby="path-label" title="Add Path" disabled>
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15" strokeWidth="0"><path d="M2.5.5V0H2v.5h.5zm10 0h.5V0h-.5v.5zM4.947 4.724a.5.5 0 00-.894-.448l.894.448zM2.5 8.494l-.447-.223-.146.293.21.251.383-.32zm5 5.997l-.384.32a.5.5 0 00.769 0l-.385-.32zm5-5.996l.384.32.21-.251-.146-.293-.447.224zm-1.553-4.219a.5.5 0 00-.894.448l.894-.448zM8 9.494v-.5H7v.5h1zm-.5-4.497A4.498 4.498 0 013 .5H2a5.498 5.498 0 005.5 5.497v-1zM2.5 1h10V0h-10v1zM12 .5a4.498 4.498 0 01-4.5 4.497v1c3.038 0 5.5-2.46 5.5-5.497h-1zM4.053 4.276l-2 3.995.895.448 2-3.995-.895-.448zM2.116 8.815l5 5.996.769-.64-5-5.996-.769.64zm5.768 5.996l5-5.996-.768-.64-5 5.996.769.64zm5.064-6.54l-2-3.995-.895.448 2 3.995.895-.448zM8 14.49V9.494H7v4.997h1z" fill="var(--aqua)"></path></svg>
            <span className="visually-hidden">Path (Coming soon)</span>
          </button>

          <div role="tooltip" id="path-label">Path (Coming soon)</div>
        </div>

      </nav>

      <nav className="actionsNav">
        {/* <div className="toolTip-wrapper">
          <button
            onClick={() => setShowGrid(!showGrid)}
            aria-labelledby="overflow-label"
            title={showGrid ? 'Hide overflow' : 'Show overflow'}>
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7 4.5h1m2 3h1m-4 0h1m-1 3h1m-4-3h1m-3.5-6h12v12h-12v-12z" stroke="currentColor"></path></svg>
            <span className="visually-hidden">{showGrid ? 'Hide Overflow' : 'Show Overflow'}</span>
          </button>
          <div role="tooltip" id="overflow-label">{showGrid ? 'Hide Overflow' : 'Show Overflow'}</div>
        </div> */}

        <div className="toolTip-wrapper">
          <button
            onClick={() => setShowGrid(!showGrid)}
            aria-labelledby="grid-label"
            title={showGrid ? 'Hide Grid' : 'Show Grid'}>
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M0 5.5h15m-15-4h15m-15 8h15m-15 4h15M9.5 0v15m4-15v15m-8-15v15m-4-15v15" stroke="currentColor"></path></svg>
            <span className="visually-hidden">{showGrid ? 'Hide Grid' : 'Show Grid'}</span>
          </button>
          <div role="tooltip" id="grid-label" className="align-left">{showGrid ? 'Hide Grid' : 'Show Grid'}</div>
        </div>

        {/* <button>
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M10.146 10.146l-.353.354.707.707.354-.353-.708-.708zM13.5 7.5l.354.354.353-.354-.353-.354-.354.354zm-2.646-3.354l-.354-.353-.707.707.353.354.708-.708zm-6.708 6.708l.354.353.707-.707-.353-.354-.708.708zM1.5 7.5l-.354-.354-.353.354.353.354L1.5 7.5zm3.354-2.646l.353-.354-.707-.707-.354.353.708.708zm6 6l3-3-.708-.708-3 3 .708.708zm3-3.708l-3-3-.708.708 3 3 .708-.708zm-9 3l-3-3-.708.708 3 3 .708-.708zm-3-2.292l3-3-.708-.708-3 3 .708.708zm6.153-6.436l-2 12 .986.164 2-12-.986-.164z" fill="currentColor"></path></svg>
          Code
        </button> */}
      </nav>
    </div >
  );
}

export default ActionsNav;