import React, { useState, useContext } from 'react';
import { SVGContext } from "./context/context";
import Layer from "./Layer";

// import { List, arrayMove, arrayRemove } from 'react-movable';

const SVGLayers = () => {

  const [SVGElements, setSVGElements] = useContext(SVGContext);
  const [showDefsPanel, setShowDefsPanel] = useState();

  return (
    <nav className="sitePanel sitePanel-layers" >
      <header
        onClick={() => setShowDefsPanel(!showDefsPanel)}
        className="sitePanel-header"
      >
        <h2>Defs</h2>
        <button title="Expand">
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 1v13M1 7.5h13" stroke="currentColor"></path></svg>
        </button>
      </header>

      {showDefsPanel &&
        <ol className="layerList">
          <defs>
            {/* {SVGElements.map((def, index) => (
                <li className="layerList-item" key={index}>
                  test
                </li>
              ))} */}
          </defs>
        </ol>
      }

      <header className="sitePanel-header">
        <h2>Layers</h2>
      </header>

      <div className="layersList-wrapper">

        {console.log(SVGElements)}

        {Object.keys(SVGElements).length === 0 &&
          <div className="hintBlock-wrapper">
            <aside className="hintBlock">
              <h2 className="hintBlock-title">Start by adding a shape</h2>
              <span>Made with ❤️ by hellogareth</span>

            </aside>
          </div>
        }

        <ol className="layerList">
          <Layer />
        </ol>

      </div >

      {/* <List
          values={SVGElements}
          removable
          onChange={({ oldIndex, newIndex }) =>
            setSVGElements(arrayMove(SVGElements, oldIndex, newIndex))
          }
          renderList={({ children, props }) =>
            <ol className="layerList" {...props}>
              {children}
            </ol>
          }
          renderItem={({ props, index }) =>

            <li className="layerList-item" {...props}>
              <span className="layerList-item-index">{index}</span>
              <div className="layerList-item-controls">

                <button className="deleteButton" onClick={({ oldIndex, newIndex }) =>
                  setSVGElements(arrayRemove(SVGElements, oldIndex, newIndex))
                }>
                  <svg viewBox="0 0 15 15" fill="none" xmlns="http:www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg>
                </button>

                <button className="moveButton">
                  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M9.5 3a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1zm-4-10a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1z" stroke="currentColor"></path></svg>
                </button>
              </div>
              {props.type}
            </li>
          }
        /> */}

    </nav >

  );
};

export default SVGLayers;