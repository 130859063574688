import React, { createElement, useContext, useRef } from 'react';
// import * as store from './services/firestore';
import { SVGContext, SVGCodeContext, GridContext } from "./context/context";

import Grid from './Grid';

function SVGBox() {

  // Context
  const [SVGElements, setSVGElements] = useContext(SVGContext);
  // const [SVGCode, setSVGCode] = useContext(SVGCodeContext);
  const [showGrid] = useContext(GridContext);

  // State and Refs
  const SVGref = useRef();

  // useEffect(() => {
  //   let SVG = SVGref.current.outerHTML.toString();
  //   SVG = SVG.replaceAll('></circle>', '/>');
  //   SVG = SVG.replaceAll('></line>', '/>');
  //   SVG = SVG.replaceAll('></path>', '/>');
  //   SVG = SVG.replaceAll('></polygon>', '/>');
  //   SVG = SVG.replaceAll('></rect>', '/>');
  //   SVG = SVG.replaceAll('/>', '/>\n');
  //   setSVGCode(SVG);
  // })


  // const copyToClipboard = (e) => {
  //   this.textArea.select();
  //   document.execCommand('copy');
  //   // This is just personal preference.
  //   // I prefer to not show the whole text area selected.
  //   e.target.focus();
  //   this.setState({ copySuccess: 'Copied!' });
  // };

  // const downloadSVGFile = (filename, contents) => {
  //   const downloadLink = document.createElement('a');
  //   downloadLink.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(contents));
  //   downloadLink.setAttribute('download', filename);
  //   downloadLink.style.display = 'none';
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // }

  // const handleDownloadSVGFile = () => {
  //   var contents = SVGCode;
  //   var fileName = "svg.svg";
  //   downloadSVGFile(fileName, contents);
  // }

  return (
    <div className="sitePanel sitePanel-svg">
      <div className="sitePanel-inner svgBox-section-wrapper">
        <section className="svgBox-section">

          <svg
            ref={SVGref}
            id="svg-box"
            viewBox={"-50 -50 100 100"}
            xmlns="http://www.w3.org/2000/svg"
          >

            {Object.entries(SVGElements).map((element) => {
              let node = createElement(element[1].nodeType, { ...element[1] })
              return node
            })}

          </svg>

          {showGrid && <Grid />}

          <svg className="svgBox-grid small" role="presentation" viewBox="0 0 100 100">
            <defs>
              <pattern id="smallGrid" width="2" height="2" patternUnits="userSpaceOnUse">
                <rect fill="rgba(255,255,255,0.25)" x="0" y="0" width="1" height="1" />
                <rect fill="rgba(255,255,255,0.25)" x="1" y="1" width="1" height="1" />
              </pattern>
            </defs>

            <rect width="100%" height="100%" fill="url(#smallGrid)" />

            <text y="-1" x="-2" fontSize="2" fill="white" textAnchor="middle">-50</text>
            <text y="-1" x="50" fontSize="2" fill="white" textAnchor="middle">0</text>
            <text y="103" x="50" fontFamily="Georgia, serif" fontStyle="italic" fontSize="3" fill="var(--aqua)" textAnchor="middle">x</text>
            <text y="-1" x="100" fontSize="2" fill="white" textAnchor="middle">50</text>
            <text y="50" x="-2" fontSize="2" fill="white" textAnchor="middle">0</text>
            <text y="50" x="-2" fontSize="2" fill="white" textAnchor="middle">0</text>
            <text y="50" x="102" fontFamily="Georgia, serif" fontStyle="italic" fontSize="3" fill="var(--aqua)" textAnchor="middle">y</text>
            <text y="100" x="-2" fontSize="2" fill="white" textAnchor="middle">50</text>
          </svg>

        </section>
      </div>
    </div>
  );
}

export default SVGBox;