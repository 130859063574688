import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";

// import Home from './Home';
// import Explore from './Explore';
import Create from './Create';
// import { singInProvider } from './services/firestore';
// import * as store from './services/firestore';

function App() {

  return (
    <Router>

      <div className="siteWrapper">
        <header className="siteHeader">
          <NavLink exact activeClassName="home" to="/">svgbox</NavLink>
          {/* <nav className="siteNav">
            <NavLink exact to="/explore" activeClassName="active">Explore</NavLink>
            <NavLink exact to="/" activeClassName="active">Create</NavLink>
          </nav> */}

          <div className="siteHeader-auth">
            {/* <button className="button">Sign up</button>
            <button className="button" onClick={singInProvider}>Log In</button> */}

            <button className="button alt">Download SVG</button>

            {/* <button
              onClick={store.submitSVG(SVGCode)}
              className="button alt"
            >
              Save
          </button> */}


          </div>
        </header>

        <Switch>
          {/* <Route path="/explore">
            <Explore />
          </Route> */}
          <Route path="/">
            <Create />
          </Route>
          {/* <Route path="/">
            <Home />
          </Route> */}
        </Switch>
      </div>

    </Router>
  );
}

export default App;
