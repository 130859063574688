import React from 'react';

function Grid() {

  return (
    <svg className="svgBox-grid" role="presentation" viewBox="0 0 100 100">
      <defs>
        <pattern id="largeGrid" width="10" height="10" patternUnits="userSpaceOnUse">
          <path strokeLinecap="round" d="M 0,0 L 0,10" />
          <path strokeLinecap="round" d="M 0,0 L 10,0" />
        </pattern>
      </defs>

      <rect width="100%" height="100%" fill="url(#largeGrid)" stroke="var(--grey-bright)" strokeWidth="0.1" />

    </svg>

  );
}

export default Grid;
