export const initialState = {
  radialGradient: {
    id: null,
    cx: null,
    cy: null,
    r: null,
    fx: null,
    fy: null,
    stops: {
      offset: null,
      stopColor: null,
    }
  },
  linearGradient: {
    id: null,
    x1: null,
    x2: null,
    y1: null,
    y2: null,
    stops: {
      offset: null,
      stopColor: null,
    }
  },
  circle: {
    cx: 0,
    cy: 0,
    r: 25,
    fill: 'crimson',
    stroke: null,
    strokeWidth: null,
    pathLength: null,
    strokeDasharray: null,
    strokeDashoffset: null
  },
  rect: {
    x: -25,
    y: -25,
    rx: 0,
    ry: 0,
    height: 50,
    width: 50,
    fill: 'mediumspringgreen',
    stroke: null,
    strokeWidth: null,
    pathLength: null,
    strokeDasharray: null,
    strokeDashoffset: null
  },
  ellipse: {
    cx: 0,
    cy: 0,
    rx: 25,
    ry: 15,
    fill: 'darkorange',
    stroke: null,
    strokeWidth: null,
    pathLength: null,
    strokeDasharray: null,
    strokeDashoffset: null
  },
  line: {
    x1: -25,
    x2: 25,
    y1: -25,
    y2: 25,
    stroke: 'pink',
    strokeWidth: 1,
    pathLength: null,
    strokeDasharray: null,
    strokeDashoffset: null,
    strokeLinecap: 'square'
  },
  polyline: {
    points: '-25,-25 25,-25 -25,25 25,25',
    fill: 'none',
    stroke: 'salmon',
    strokeWidth: 1,
    pathLength: null,
    strokeDasharray: null,
    strokeDashoffset: null
  },
  polygon: {
    points: '0,-25 -35,25 35, 25',
    fill: 'darkorchid',
    stroke: 'white',
    strokeWidth: 1,
    pathLength: null,
    strokeDasharray: null,
    strokeDashoffset: null
  }
}

export const svgAttrsReducer = (state = initialState, action) => {

  switch (action.type) {

    case 'ADD_CIRCLE':
      return {
        ...state,
        ...state, [action.nodeId]: { ...initialState.circle, nodeType: action.nodeType, nodeId: action.nodeId },
      }

    case 'ADD_RECT':
      return {
        ...state,
        ...state, [action.nodeId]: { ...initialState.rect, nodeType: action.nodeType, nodeId: action.nodeId },
      }

    case 'ADD_ELLIPSE':
      return {
        ...state,
        ...state, [action.nodeId]: { ...initialState.ellipse, nodeType: action.nodeType, nodeId: action.nodeId },
      }

    case 'ADD_LINE':
      return {
        ...state,
        ...state, [action.nodeId]: { ...initialState.line, nodeType: action.nodeType, nodeId: action.nodeId },
      }

    case 'ADD_POLYGON':
      return {
        ...state,
        ...state, [action.nodeId]: { ...initialState.polygon, nodeType: action.nodeType, nodeId: action.nodeId },
      }


    case 'ADD_POLYLINE':
      return {
        ...state,
        ...state, [action.nodeId]: { ...initialState.polyline, nodeType: action.nodeType, nodeId: action.nodeId },
      }

    case 'CLONE_NODE':
      return {
        ...state,
        ...state, [action.nodeId]: { ...action.prevState, nodeId: action.nodeId }
      }

    case 'UPDATE_ELEMENT_ATTRS':
      return {
        ...state,
        [action.nodeId]: {
          ...state[action.nodeId],
          [action.attrKey]: action.attrValue
        }
      }

    case 'DELETE_NODE':
      const removableNode = { ...state }
      delete removableNode[action.nodeId]
      return removableNode

    default:
      return state;
  }
}